export default class GameScoreUnity {
    constructor(gs, unity) {
        this.gs = gs;
        this.unity = unity;

        this.gs.player.ready.then(() => {
            this.trigger('CallSDKReady');
            this.trigger('CallPlayerReady');
        });

        this.gs.player.on('change', () => this.trigger('CallPlayerChange'));
        this.gs.player.on('sync', (success) => {
            this.trigger(success ? 'CallPlayerSyncComplete' : 'CallPlayerSyncError');
        });
        this.gs.player.on('load', (success) => {
            this.trigger(success ? 'CallPlayerLoadComplete' : 'CallPlayerLoadError');
        });
        this.gs.player.on('login', (success) => {
            this.trigger(success ? 'CallPlayerLoginComplete' : 'CallPlayerLoginError');
        });

        this.gs.player.on('fetchFields', (success) => {
            if (success) {
                this.trigger('CallPlayerFetchFieldsComplete', JSON.stringify(this.gs.player.fields.map((field) => ({ ...field, defaultValue: field.default }))));
            } else {
                this.trigger('CallPlayerFetchFieldsError');
            }
        });

        // leaderboard
        this.gs.leaderboard.on('open', () => this.trigger('CallLeaderboardOpen'));

        // achievements
        this.gs.achievements.on('open', () => this.trigger('CallAchievementsOpen'));
        this.gs.achievements.on('close', () => {
            this.trigger('CallAchievementsClose');
            window.focus();
        });

        this.gs.achievements.on('unlock', () => this.trigger('CallAchievementsUnlock'));
        this.gs.achievements.on('error:unlock', () => this.trigger('CallAchievementsUnlockError'));

        // games collections
        this.gs.gamesCollections.on('open', () => this.trigger('CallGamesCollectionsOpen'));
        this.gs.gamesCollections.on('close', () => {
            this.trigger('CallGamesCollectionsClose');
            window.focus();
        });

        // fullscreen
        this.gs.fullscreen.on('open', () => this.trigger('CallFullscreenOpen'));
        this.gs.fullscreen.on('close', () => this.trigger('CallFullscreenClose'));
        this.gs.fullscreen.on('change', () => this.trigger('CallFullscreenChange'));

        // ads
        this.gs.ads.on('start', () => this.trigger('CallAdsStart'));
        this.gs.ads.on('close', (success) => {
            this.trigger('CallAdsClose', success);
            window.focus();
        });

        this.gs.ads.on('fullscreen:start', () => this.trigger('CallAdsFullscreenStart'));
        this.gs.ads.on('fullscreen:close', (success) => this.trigger('CallAdsFullscreenClose', success));

        this.gs.ads.on('preloader:start', () => this.trigger('CallAdsPreloaderStart'));
        this.gs.ads.on('preloader:close', (success) => this.trigger('CallAdsPreloaderClose', success));

        this.gs.ads.on('rewarded:start', () => this.trigger('CallAdsRewardedStart'));
        this.gs.ads.on('rewarded:close', (success) => this.trigger('CallAdsRewardedClose', (success)));
        this.gs.ads.on('rewarded:reward', () => this.trigger('CallAdsRewardedReward', this.lastRewardedTag));

        this.gs.ads.on('sticky:start', () => this.trigger('CallAdsStickyStart'));
        this.gs.ads.on('sticky:close', () => this.trigger('CallAdsStickyClose'));
        this.gs.ads.on('sticky:refresh', () => this.trigger('CallAdsStickyRefresh'));
        this.gs.ads.on('sticky:render', () => this.trigger('CallAdsStickyRender'));

        // socials
        this.gs.socials.on('share', (success) => this.trigger('CallSocialsShare', success));
        this.gs.socials.on('post', (success) => this.trigger('CallSocialsPost', success));
        this.gs.socials.on('invite', (success) => this.trigger('CallSocialsInvite', success));
        this.gs.socials.on('joinCommunity', (success) => this.trigger('CallSocialsJoinCommunity', success));

        // gs
        this.gs.on('change:language', (lang) => this.trigger('CallChangeLanguage', lang));
        this.gs.on('change:avatarGenerator', (ag) => this.trigger('CallChangeAvatarGenerator', ag));
        this.gs.on('pause', () => this.trigger('CallOnPause'));
        this.gs.on('resume', () => this.trigger('CallOnResume'));
    }
    trigger(eventName, value) {

        this.unity.SendMessage('GameScoreSDK', eventName, this.toUnity(value));
    }

    toUnity(value) {
        switch (typeof (value)) {
            case 'boolean': {
                return String(value);
            }
            case 'number': {
                return value;
            }
            case 'string': {
                return value;
            }
            case 'object': {
                return JSON.stringify(value);
            }
        }
        return value;
    }

    Language() {
        return this.gs.language;
    }
    AvatarGenerator() {
        return this.gs.avatarGenerator;
    }
    PlatformType() {
        return this.gs.platform.type;
    }
    PlatformHasIntegratedAuth() {
        return this.toUnity(this.gs.platform.hasIntegratedAuth);
    }
    PlatformIsExternalLinksAllowed() {
        return this.toUnity(this.gs.platform.isExternalLinksAllowed);
    }

    AppTitle() {
        return this.gs.app.title;
    }
    AppDescription() {
        return this.gs.app.description;
    }
    AppImage() {
        return this.gs.app.image;
    }
    AppUrl() {
        return this.gs.app.url;
    }


    PlayerGetID() {
        return this.gs.player.id;
    }

    PlayerGetScore() {
        return this.gs.player.score;
    }
    PlayerSetScore(score) {
        this.gs.player.score = Number(score);
    }
    PlayerAddScore(score) {
        this.gs.player.score += Number(score);
    }

    PlayerGetName() {
        return this.gs.player.name;
    }
    PlayerSetName(name) {
        this.gs.player.name = name;
    }

    PlayerGetAvatar() {
        return this.gs.player.avatar;
    }
    PlayerSetAvatar(src) {
        this.gs.player.avatar = src;
    }

    PlayerGet(key) {
        return this.toUnity(this.gs.player.get(key));
    }

    PlayerSet(key, value) {
        this.gs.player.set(key, value);
    }
    PlayerAdd(key, value) {
        this.gs.player.add(key, Number(value));
    }

    PlayerHas(key) {
        return this.toUnity(this.gs.player.has(key));
    }

    PlayerSetFlag(key, value) {
        this.gs.player.set(key, !Boolean(value));
    }
    PlayerToggle(key) {
        this.gs.player.toggle(key);
    }

    PlayerGetFieldName(key) {
        return this.gs.player.getFieldName(key);
    }
    PlayerGetFieldVariantName(key, value) {
        return this.gs.player.getFieldVariantName(key, value);
    }
    PlayerGetFieldVariantAt(key, index) {
        const variant = this.gs.player.getField(key).variants[index];
        return variant ? variant.value : '';
    }
    PlayerGetFieldVariantIndex(key, value) {
        return this.gs.player.getField(key).variants.findIndex((v) => v.value === value);
    }

    PlayerReset() {
        this.gs.player.reset();
    }
    PlayerRemove() {
        this.gs.player.remove();
    }
    PlayerSync(override = false) {
        return this.gs.player.sync({ override: Boolean(override) });
    }
    PlayerLoad() {
        return this.gs.player.load();
    }
    PlayerLogin() {
        return this.gs.player.login();
    }
    PlayerFetchFields() {
        this.gs.player.fetchFields();
    }

    PlayerIsLoggedIn() {
        return this.toUnity(this.gs.player.isLoggedIn);
    }

    PlayerHasAnyCredentials(key) {
        return this.toUnity(this.gs.player.hasAnyCredentials);
    }

    PlayerIsStub(key) {
        return this.toUnity(this.gs.player.isStub);
    }


    LeaderboardOpen(orderBy, order, limit, withMe, includeFields, displayFields) {
        return this.gs.leaderboard
            .open({
                id: this.gs.player.id,
                orderBy: orderBy
                    .split(',')
                    .map((o) => o.trim())
                    .filter((f) => f),
                order,
                limit: Number(limit),
                withMe,
                includeFields: includeFields
                    .split(',')
                    .map((o) => o.trim())
                    .filter((f) => f),
                displayFields: displayFields
                    .split(',')
                    .map((o) => o.trim())
                    .filter((f) => f),
            })
            .catch(console.warn);
    }

    LeaderboardFetch(tag, orderBy, order, limit, withMe, includeFields) {
        return this.gs.leaderboard
            .fetch({
                id: this.gs.player.id,
                orderBy: orderBy
                    .split(',')
                    .map((o) => o.trim())
                    .filter((f) => f),
                order,
                limit: Number(limit),
                withMe,
                includeFields: includeFields
                    .split(',')
                    .map((o) => o.trim())
                    .filter((f) => f),
            })
            .then((leaderboardInfo) => {
                // this.lastLeaderboardTag = tag;
                // this.leaderboard = leaderboardInfo.players;

                this.trigger('CallLeaderboardFetchTag', tag);
                this.trigger('CallLeaderboardFetch', JSON.stringify(leaderboardInfo.players));
            })
            .catch((err) => {
                console.warn(err);
                // this.lastLeaderboardTag = tag;
                this.trigger('CallLeaderboardFetchError');
            });
    }

    LeaderboardFetchPlayerRating(tag, orderBy, order) {
        return this.gs.leaderboard
            .fetchPlayerRating({
                id: this.gs.player.id,
                orderBy: orderBy
                    .split(',')
                    .map((o) => o.trim())
                    .filter((f) => f),
                order,
            })
            .then((result) => {
                // this.lastLeaderboardPlayerRatingTag = tag;
                // this.leaderboardPlayerPosition = result.player.position;
                this.trigger('CallLeaderboardFetchPlayerTag', tag);
                this.trigger('CallLeaderboardFetchPlayer', result.player.position);
            })
            .catch((err) => {
                console.warn(err);
                // this.lastLeaderboardPlayerRatingTag = tag;
                this.trigger('CallLeaderboardFetchPlayerError');
            });
    }




    /* LEADER BOARD SCOPED */

    LeaderboardScopedOpen(id, tag, variant, order, limit, includeFields, displayFields, withMe) {
        return this.gs.leaderboard
            .openScoped({
                id: Number(id),
                tag,
                variant,
                order,
                limit: Number(limit),
                includeFields: includeFields
                    .split(',')
                    .map((o) => o.trim())
                    .filter((f) => f),
                displayFields: displayFields
                    .split(',')
                    .map((o) => o.trim())
                    .filter((f) => f),
                withMe,
            })
            .catch(console.warn);
    }

    LeaderboardScopedFetch(id, tag, variant, order, limit, includeFields, withMe) {
        return this.gs.leaderboard
            .fetchScoped({
                id: Number(id),
                tag,
                variant,
                order,
                limit: Number(limit),
                includeFields: includeFields
                    .split(',')
                    .map((o) => o.trim())
                    .filter((f) => f),
                withMe,
            })
            .then((leaderboardScopedInfo) => {
                // this.lastLeaderboardTag = tag;
                // this.leaderboard = leaderboardScopedInfo.players;
                this.trigger('CallLeaderboardScopedFetchTag', tag);
                this.trigger('CallLeaderboardScopedFetchVariant', variant);
                this.trigger('CallLeaderboardScopedFetch', JSON.stringify(leaderboardScopedInfo.players));
            })
            .catch((err) => {
                console.warn(err);
                // this.lastLeaderboardTag = tag;
                this.trigger('CallLeaderboardScopedFetchError');
            });
    }

    LeaderboardScopedPublishRecord(id, tag, variant, override, key1, value1, key2, value2, key3, value3) {
        return this.gs.leaderboard
            .publishRecord({
                id: Number(id),
                tag,
                variant,
                override: Boolean(override),
                record: {
                    [key1]: Number(value1),
                    [key2]: Number(value2),
                    [key3]: Number(value3),
                },
            })
            .then(() => {
                this.trigger('CallLeaderboardScopedPublishRecordComplete');
            })
            .catch((err) => {
                console.warn(err);
                this.trigger('CallLeaderboardScopedPublishRecordError');
            });
    }

    LeaderboardScopedFetchPlayerRating(id, tag, variant, includeFields) {
        return this.gs.leaderboard
            .fetchPlayerRatingScoped({
                id: Number(id),
                tag,
                variant,
                includeFields: includeFields
                    .split(',')
                    .map((o) => o.trim())
                    .filter((f) => f),
            })
            .then((result) => {
                // this.lastLeaderboardPlayerRatingTag = tag;
                // this.leaderboardPlayerPosition = result.player.position;

                this.trigger('CallLeaderboardScopedFetchPlayerTag', tag);
                this.trigger('CallLeaderboardScopedFetchPlayerVariant', variant);
                this.trigger('CallLeaderboardScopedFetchPlayer', result.player.position);
            })
            .catch((err) => {
                console.warn(err);
                // this.lastLeaderboardPlayerRatingTag = tag;
                this.trigger('CallLeaderboardFetchPlayerError');
            });
    }
    /* LEADER BOARD SCOPED */



    AchievementsOpen() {
        return this.gs.achievements.open().catch(console.warn);
    }
    AchievementsFetch() {
        return this.gs.achievements
            .fetch()
            .then((result) => {
                // this.achievements = result.achievements;
                // this.achievementsGroups = result.achievementsGroups;
                // this.playerAchievements = result.playerAchievements;

                this.trigger('CallAchievementsFetch', JSON.stringify(result.achievements));
                this.trigger('CallAchievementsFetchGroups', JSON.stringify(result.achievementsGroups));
                this.trigger('CallPlayerAchievementsFetch', JSON.stringify(result.playerAchievements));
            })
            .catch((err) => {
                console.warn(err);
                this.trigger('CallAchievementsFetchError');
            });
    }
    AchievementsUnlock(idOrTag) {
        const id = parseInt(idOrTag, 10) || 0;
        const query = id > 0 ? { id } : { tag: idOrTag };
        return this.gs.achievements
            .unlock(query)
            .then((result) => {
                // this.isUnlockAchievementSuccess = result.success;
                // this.unlockAchievementError = result.error || '';

                // const achievement = result.achievement || {};
                // this.unlockedAchievementId = achievement.id || 0;
                // this.unlockedAchievementTag = achievement.tag || '';
                // this.unlockedAchievementName = achievement.name || '';
                // this.unlockedAchievementDescription = achievement.description || '';
                // this.unlockedAchievementIcon = achievement.icon || '';
                // this.unlockedAchievementIconSmall = achievement.iconSmall || '';
                // this.unlockedAchievementRare = achievement.rare || 'COMMON';

                if (result.success) {
                    this.trigger('CallAchievementsUnlock', idOrTag);
                    return;
                }

                this.trigger('CallAchievementsUnlockError');
            })

            .catch((err) => {
                console.warn(err);
                this.trigger('CallAchievementsUnlockError');
            });
    }
    PaymentsFetchProducts() {
        return this.gs.payments
            .fetchProducts()
            .then((result) => {
                // this.products = result.products;
                // this.playerPurchases = result.playerPurchases;
                this.trigger('CallPaymentsFetchProducts', JSON.stringify(result.products));
                this.trigger('CallPaymentsFetchPlayerPurcahses', JSON.stringify(result.playerPurchases));
            })
            .catch((err) => {
                console.warn(err);
                this.trigger('CallPaymentsFetchProductsError');
            });
    }
    PaymentsPurchase(idOrTag) {
        const id = parseInt(idOrTag, 10) || 0;
        const query = id > 0 ? { id } : { tag: idOrTag };
        return this.gs.payments
            .purchase(query)
            .then((result) => {
                // this.isPurchaseProductSuccess = result.success;
                // this.purchaseProductError = result.error || '';

                // const product = result.product || {};
                // this.purchasedProductId = product.id || 0;
                // this.purchasedProductTag = product.tag || '';

                if (result.success) {
                    this.trigger('CallPaymentsPurchase', idOrTag);
                    window.focus();
                    return;
                }

                this.trigger('CallPaymentsPurchaseError');

                window.focus();
            })
            .catch((err) => {
                console.warn(err);
                this.trigger('CallPaymentsPurchaseError');
                window.focus();
            });
    }
    PaymentsConsume(idOrTag) {
        const id = parseInt(idOrTag, 10) || 0;
        const query = id > 0 ? { id } : { tag: idOrTag };
        return this.gs.payments
            .consume(query)
            .then((result) => {
                // this.isConsumeProductSuccess = result.success;
                // this.consumeProductError = result.error || '';

                // const product = result.product || {};
                // this.consumedProductId = product.id || 0;
                // this.consumedProductTag = product.tag || '';

                if (result.success) {
                    this.trigger('CallPaymentsConsume', idOrTag);
                    window.focus();
                    return;
                }

                this.trigger('CallPaymentsConsumeError');
            })
            .catch((err) => {
                console.warn(err);
                this.trigger('CallPaymentsConsumeError');
            });
    }


    PaymentsIsAvailable() {
        return this.toUnity(this.gs.payments.isAvailable);
    }


    FullscreenOpen() {
        return this.gs.fullscreen.open();
    }
    FullscreenClose() {
        return this.gs.fullscreen.close();
    }
    FullscreenToggle() {
        return this.gs.fullscreen.toggle();
    }
    AdsShowFullscreen() {
        return this.gs.ads.showFullscreen();
    }
    AdsShowRewarded(idOrTag) {
        this.lastRewardedTag = idOrTag;
        return this.gs.ads.showRewardedVideo();
    }
    AdsShowPreloader() {
        return this.gs.ads.showPreloader();
    }
    AdsShowSticky() {
        return this.gs.ads.showSticky();
    }
    AdsCloseSticky() {
        return this.gs.ads.closeSticky();
    }
    AdsRefreshSticky() {
        return this.gs.ads.refreshSticky();
    }


    AdsIsAdblockEnabled() {
        return this.toUnity(this.gs.ads.isAdblockEnabled);
    }

    AdsIsStickyAvailable() {
        return this.toUnity(this.gs.ads.isStickyAvailable);
    }
    AdsIsFullscreenAvailable() {
        return this.toUnity(this.gs.ads.isFullscreenAvailable);
    }
    AdsIsRewardedAvailable() {
        return this.toUnity(this.gs.ads.isRewardedAvailable);
    }
    AdsIsPreloaderAvailable() {
        return this.toUnity(this.gs.ads.isPreloaderAvailable);
    }


    AdsIsStickyPlaying() {
        return this.toUnity(this.gs.ads.isStickyPlaying);
    }
    AdsIsFullscreenPlaying() {
        return this.toUnity(this.gs.ads.isFullscreenPlaying);
    }
    AdsIsRewardedPlaying() {
        return this.toUnity(this.gs.ads.isRewardedPlaying);
    }
    AdsIsPreloaderPlaying() {
        return this.toUnity(this.gs.ads.isPreloaderPlaying);
    }


    AnalyticsHit(url) {
        return this.gs.analytics.hit(url);
    }
    AnalyticsGoal(event, value) {
        return this.gs.analytics.goal(event, value);
    }
    SocialsShare(text, url, image) {
        return this.gs.socials.share({ text, url, image });
    }
    SocialsPost(text, url, image) {
        return this.gs.socials.post({ text, url, image });
    }
    SocialsInvite(text, url, image) {
        return this.gs.socials.invite({ text, url, image });
    }
    SocialsJoinCommunity() {
        return this.gs.socials.joinCommunity();
    }
    SocialsCommunityLink() {
        return this.toUnity(this.gs.socials.communityLink);
    }


    SocialsIsSupportsNativeShare() {
        return this.toUnity(this.gs.socials.isSupportsNativeShare);
    }
    SocialsIsSupportsNativePosts() {
        return this.toUnity(this.gs.socials.isSupportsNativePosts);
    }
    SocialsIsSupportsNativeInvite() {
        return this.toUnity(this.gs.socials.isSupportsNativeInvite);
    }
    SocialsCanJoinCommunity() {
        return this.toUnity(this.gs.socials.canJoinCommunity);
    }
    SocialsIsSupportsNativeCommunityJoin() {
        return this.toUnity(this.gs.socials.isSupportsNativeCommunityJoin);
    }

    // games collections
    GamesCollectionsOpen(idOrTag) {
        const id = parseInt(idOrTag, 10) || 0;
        const query = id > 0 ? { id } : { tag: idOrTag || 'ANY' };
        return this.gs.gamesCollections.open(query);
    }
    GamesCollectionsFetch(idOrTag) {
        const id = parseInt(idOrTag, 10) || 0;
        const query = id > 0 ? { id } : { tag: idOrTag };
        return this.gs.gamesCollections
            .fetch(query)
            .then((result) => {
                // this.lastGamesCollectionIdOrTag = idOrTag;
                // this.gamesCollection = result;
                this.trigger('CallGamesCollectionsFetchTag', idOrTag);
                this.trigger('CallGamesCollectionsFetch', JSON.stringify(result));

            })
            .catch((err) => {
                console.warn(err);
                // this.lastGamesCollectionIdOrTag = idOrTag;
                // this.gamesCollectionFetchError = (err && err.message) || '';
                this.trigger('CallGamesCollectionsFetchError');
            });
    }

    ChangeLanguage(language) {
        return this.gs.changeLanguage(language);
    }
    ChangeLanguageByCode(language = '') {
        return this.gs.changeLanguage(language.toLowerCase());
    }
    ChangeAvatarGenerator(generator) {
        return this.gs.changeAvatarGenerator(generator);
    }
    LoadOverlay() {
        return this.gs.loadOverlay();
    }

    // GAME
    IsPaused() {
        return this.toUnity(this.gs.isPaused);
    }

    Pause() {
        return this.gs.pause();
    }
    Resume() {
        return this.gs.resume();
    }
    // GAME


    //Device
    IsMobile() {
        return this.toUnity(this.gs.isMobile);
    }
    //Device


    // Server
    ServerTime() {
        return this.toUnity(this.gs.serverTime);
    }
    // Server



    // System
    IsDev() {
        return this.toUnity(this.gs.isDev);
    }

    IsAllowedOrigin() {
        return this.toUnity(this.gs.isAllowedOrigin);
    }
    // System



}

window.GameScoreUnity = GameScoreUnity;
